import axios from 'axios';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SAVE_STYLES_ACTION } from '../../constants';
import './login.css'

const Login = () => {

    const defaultDetails = {
        email: "",
        password: ""
    };

    let history = useHistory();

    const dispatch = useDispatch();

    const [details, setDetails] = useState(defaultDetails);
    const [usernameFilled, setUsernameFilled] = useState(true);
    const [passwordFilled, setPasswordFilled] = useState(true);
    const [failed, setFailed] = useState(false)

    const saveToken = data => {
        localStorage.setItem("toogethr-pay-token", data.token)
    }

    const cleanUpFields = () => {
        setDetails(defaultDetails);
    }

    const dispatchSavingGlobalStyles = (data) => {
        dispatch({type: SAVE_STYLES_ACTION, data: {
            textFont: data.text_font,
            backgroundColor: data.background_color,
            buttonColor: data.button_color,
            buttonTextColor: data.button_text_color,
            logo: data.logo,
            backgroundImage: data.background
        }})
    }

    const checkFields = () => {
        setUsernameFilled(details.email !== "");
        setPasswordFilled(details.password !== "");
        return details.email && details.password;
    }

    const loginHandler = e => {
        e.preventDefault();
        const filled = checkFields();
        if (!filled) {
            return;
        }
        const data = {
            'username': details.email,
            'password': details.password
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        axios.post("api/login/", data, headers).then(response => {
            const token = response.data;
            saveToken(token);
            const headers = {
                "Authorization": "Token " + token.token
            }
            axios.get("parking/styles/", {
                headers: headers
            }).then(response => {
                dispatchSavingGlobalStyles(response.data);
                history.push("/");
            })
        }).catch(error => {
            setFailed(true);
            cleanUpFields();
        });
    };

    const handleChangeEmail = event => {
        setDetails({...details, email: event.target.value})
    }

    const handleChangePassword = event => {
        setDetails({...details, password: event.target.value})
    }

    const focusUsername = () => {
        setUsernameFilled(true);
    }

    const focusPassword = () => {
        setPasswordFilled(true);
    }
 
    return (
        <div className="login-div">
            <form className="login" onSubmit={e => loginHandler(e)}>
                <div className="form-fields">
                    <h2>Login</h2>
                    <div className="form-group">
                        <label htmlFor="email">Email adress</label>
                        <input className={!usernameFilled ? 'not-filled' : ""} 
                            type="email"
                            name="email"
                            id="email" 
                            onChange={handleChangeEmail}
                            onFocus={focusUsername} 
                            value={details.email}/>
                    </div>
                    {!usernameFilled ? <p className="details-message">Email missing!</p> : ""}
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input className={!passwordFilled ? 'not-filled' : ""} 
                            type="password" 
                            name="password" 
                            id="password" 
                            onChange={handleChangePassword} 
                            onFocus={focusPassword}
                            value={details.password}/>
                    </div>
                    {!passwordFilled ? <p className="details-message">Password missing!</p> : ""}
                    <input type="submit" className="input-submit" value="Login"/>
                    {failed ? <p className="message">Please try again!</p> : ""}
                </div>
            </form>
            
        </div>
    )
}

export default Login
