import React, { useCallback, useEffect, useState } from 'react';
import './index.css';
import Login from './components/login/Login';
import Home from './components/home/Home'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import Details from './components/details/Details';
import { SAVE_STYLES_ACTION } from './constants';
import { useDispatch } from 'react-redux';
import Summary from './components/summary/Summary';

function App() {

  const [globalStyles, setGlobalStyles] = useState({})

  const dispatch = useDispatch();
  
  const dispatchSavingGlobalStyles = (data) => {
    dispatch({type: SAVE_STYLES_ACTION, data: {
      textFont: data.text_font,
      backgroundColor: data.background_color,
      buttonColor: data.button_color,
      buttonTextColor: data.button_text_color,
      logo: data.logo,
      backgroundImage: data.background
    }})
  }

  const getStyle = useCallback((token) => {
    const headers = {
      "Authorization": "Token " + token
    }
    axios.get("parking/styles/", {
      headers: headers
    }).then(response => {
      const data = response.data
      dispatch({type: SAVE_STYLES_ACTION, data: {
        textFont: data.text_font,
        backgroundColor: data.background_color,
        buttonColor: data.button_color,
        buttonTextColor: data.button_text_color,
        logo: data.logo,
        backgroundImage: data.background
      }})
    })
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem('toogethr-pay-token');
    if (token) {
      setGlobalStyles(prev => ({...prev, ...getStyle(token)}));
    }
  }, [getStyle])

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/login" exact component={Login}/>
          <Route path="/details" exact component={Details}/> 
          <Route path="/summary" exact component={Summary}/>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
