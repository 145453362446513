import { createStore } from 'redux';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_BACKGROUND_IMAGE, DEFAULT_BUTTON_COLOR, DEFAULT_BUTTON_TEXT_COLOR, DEFAULT_LOGO, DEFAULT_TEXT_FONT, SAVE_DISCOUNT, SAVE_STYLES_ACTION } from '../constants';

const initialState = {
    textFont: DEFAULT_TEXT_FONT,
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
    buttonColor: DEFAULT_BUTTON_COLOR,
    buttonTextColor: DEFAULT_BUTTON_TEXT_COLOR,
    logo: DEFAULT_LOGO,
    backgroundImage: DEFAULT_BACKGROUND_IMAGE,
    discount: 0
}

const reducer = (state = initialState, action) => {
    if (action.type === SAVE_STYLES_ACTION){
        return {
            textFont: action.data.textFont,
            backgroundColor: action.data.backgroundColor,
            buttonColor: action.data.buttonColor,
            buttonTextColor: action.data.buttonTextColor,
            logo: action.data.logo,
            backgroundImage: action.data.backgroundImage,
            discount: state.discount
        };
    }

    if (action.type === SAVE_DISCOUNT) {
        return {
            textFont: state.textFont,
            backgroundColor: state.backgroundColor,
            buttonColor: state.buttonColor,
            buttonTextColor: state.buttonTextColor,
            logo: state.logo,
            backgroundImage: state.backgroundImage,
            discount: action.discount
        }
    }
    return state;
}

const store = createStore(reducer)

export default store;
