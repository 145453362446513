import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './summary.css';

const Summary = () => {

    const history = useHistory();

    const textFont = useSelector(state => state.textFont);
    const backgroundColor = useSelector(state => state.backgroundColor)
    const buttonColor = useSelector(state => state.buttonColor)
    const buttonTextColor = useSelector(state => state.buttonTextColor)
    const logo = useSelector(state => state.logo)
    const backgroundImage = useSelector(state => state.backgroundImage)
    const discount = useSelector(state => state.discount)

    const mainSummaryStyles = {
        backgroundColor: backgroundColor,
        fontFamily: textFont
    }

    const buttonStyle = {
        backgroundColor: buttonColor,
        color: buttonTextColor
    }

    const handleClose = (e) => {
        e.preventDefault();
        history.push("/");
    }

    useEffect(() => {
        const token = localStorage.getItem('toogethr-pay-token');
        if (token === null) {
            history.push('/login');
            return;
        }
    })

    return (
        <div className="summary-main" style={{
            ...mainSummaryStyles,
            backgroundImage: `url(${process.env.REACT_APP_API_URL}images/${backgroundImage})`
        }}>
            {logo !== '' ?
            <img src={`${process.env.REACT_APP_API_URL}images/${logo}`} 
                    alt="logo" className="image"></img>: 
            ''}
            <div className="summary-details">
                <p>Thank you!</p>
                <h1>You can now drive out with {discount > 0 ? `${discount}%` : ''} discount</h1>
                <p className="summary-p">Please note that any remaining amount must be paid at the gate</p>
                <button className="close-btn" style={buttonStyle} onClick={e => handleClose(e)}>Close</button>
            </div>
        </div>
    )
}

export default Summary;
