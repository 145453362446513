import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { SAVE_DISCOUNT } from '../../constants';
import './details.css';

const Details = () => {

    const history = useHistory();

    const dispatch = useDispatch();

    const [communities, setCommunities] = useState([]);
    const [communitiesOptions, setCommunitiesOptions] = useState([]);
    const [name, setName] = useState("");
    const [licencePlate, setLicencePlate] = useState("");
    const [selectedCommunity, setSelectedCommunity] = useState("");
    const [nameFilled, setNameFilled] = useState(true)
    const [plateFilled, setPlateFilled] = useState(true)
    const [communityFilled, setCommunityFilled] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")


    const textFont = useSelector(state => state.textFont);
    const backgroundColor = useSelector(state => state.backgroundColor)
    const buttonColor = useSelector(state => state.buttonColor)
    const buttonTextColor = useSelector(state => state.buttonTextColor)
    const logo = useSelector(state => state.logo)
    const backgroundImage = useSelector(state => state.backgroundImage)

    const mainDivStyles = {
        backgroundColor: backgroundColor,
        fontFamily: textFont
    }

    const buttonStyle = {
        backgroundColor: buttonColor,
        color: buttonTextColor
    }
   
    const checkFields = () => {
        setNameFilled(name !== "");
        setPlateFilled(licencePlate !== "");
        setCommunityFilled(selectedCommunity !== "");
        return name && licencePlate && selectedCommunity;
    }

    const submitHandler = (e) => {
        e.preventDefault();
        const check = checkFields();
        if (check) {
            const token = localStorage.getItem('toogethr-pay-token');

            const headers = {
                "Authorization": "Token " + token
            }
            axios.post("parking/apply-discount/",
                {
                    licencePlate: licencePlate,
                    organisation: selectedCommunity
                },
                { headers: headers, }
            ).then(response => {
                history.push("/summary")
            }).catch(err => {
                let responsedata = err.response.data
                if (responsedata && responsedata.error) {
                    setErrorMessage(responsedata.error)
                }
            });
        }
    }
    
    useEffect(() => {
        const token = localStorage.getItem('toogethr-pay-token');
        if (token === null) {
            history.push('/login');
            return;
        }
        const headers = {
            "Authorization": "Token " + token
        }
        axios.get("parking/organisations/", {
            headers: headers
        }).then(response => {
            const optionsForDropwown = [{label: '', value: ''}];
            setCommunities(response.data["organisations"])
            response.data["organisations"].map(org => 
                optionsForDropwown.push({label: org.name, value: org.id})
            )
            setCommunitiesOptions(optionsForDropwown);
        })
    }, [history]);

    const handleNameEmail = e => {
        setName(e.target.value);
    }

    const handleLicencePlateEmail = e => {
        setLicencePlate(e.target.value);
    }

    const handleCommunitySelect = option => {
        let discount = 0;
        if (option.value) {
            discount = communities.find(com => com.id === option.value).percentage;
        }
        setCommunityFilled(communityFilled !== '');
        setSelectedCommunity(option.value);
        dispatch({type: SAVE_DISCOUNT, discount: discount});
        return;
    }

    const changeNameFilledClass = () => {
        setNameFilled(true);
    }

    const changePlateFilledClass = () => {
        setPlateFilled(true);
    }

    const changeCommunityFilledClass = () => {
        setCommunityFilled(true);
    }

    return (
        <div className="details-main" style={{
            ...mainDivStyles,
            backgroundImage: `url(${process.env.REACT_APP_API_URL}images/${backgroundImage})`
        }}>
            <div className="details">
                {logo !== '' ? 
                <img src={`${process.env.REACT_APP_API_URL}images/${logo}`} 
                    alt="logo" className="image-details"></img>: 
                ''}
                <div className="details-text">
                    <h1>Get a discount on parking</h1>
                    <p>Please enter your details below to receive your parking discount</p>
                </div>
                {errorMessage !== '' ?
                 <div className="details-error-text">
                    <p>{errorMessage}</p>
                </div>:
                ''}
                <form onSubmit={e => submitHandler(e)}>
                    <div className="form-fields">
                        <div className="form-group">
                            <label htmlFor="text">Name</label>
                            <input className={!nameFilled ? 'not-filled' : ''}
                                onFocus={changeNameFilledClass} 
                                type="text"
                                name="name"
                                id="name"
                                onChange={handleNameEmail} 
                                value={name} 
                                />
                        </div>
                        {!nameFilled ? <p className="details-message">Name not found!</p> : ""}
                        <div className="form-group">
                            <label htmlFor="text">Licence plate</label>
                            <input className={!plateFilled ? 'not-filled' : ''}
                                onFocus={changePlateFilledClass} 
                                type="text"
                                name="licence"
                                id="licence" 
                                onChange={handleLicencePlateEmail} 
                                value={licencePlate}
                                />
                        </div>
                        {!plateFilled ? <p className="details-message">Licence plate not found!</p> : ""}
                        <div className="form-group">
                            <label htmlFor="text">Community</label>
                            <Select 
                                onFocus={changeCommunityFilledClass} 
                                className={!communityFilled ? 'not-filled' : ''}
                                options={communitiesOptions}
                                onChange={(option) => handleCommunitySelect(option)}
                             />
                        </div>
                        {!communityFilled ? <p className="details-message">Choose one of communities!</p> : ""}
                        <input type="submit" className="details-submit" style={buttonStyle} value="Submit"/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Details
