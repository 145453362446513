import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './home.css';

const Home = () => {

    let history = useHistory();

    const goToDetailsForm = () => {
        history.push('/details')
    }

    const textFont = useSelector(state => state.textFont);
    const backgroundColor = useSelector(state => state.backgroundColor)
    const buttonColor = useSelector(state => state.buttonColor)
    const buttonTextColor = useSelector(state => state.buttonTextColor)
    const logo = useSelector(state => state.logo)
    const backgroundImage = useSelector(state => state.backgroundImage)

    const homeDivStyles = {
        backgroundColor: backgroundColor,
        fontFamily: textFont
    }

    const buttonStyle = {
        backgroundColor: buttonColor,
        color: buttonTextColor
    }

    useEffect(() => {
        const token = localStorage.getItem('toogethr-pay-token');
        if (token === null) {
            history.push('/login');
            return;
        }
    }, [history])

    return (
        <div className="home" 
            style={{
                ...homeDivStyles,
                backgroundImage: `url(${process.env.REACT_APP_API_URL}images/${backgroundImage})`
         }} >
            {logo !== '' ? 
            <img src={`${process.env.REACT_APP_API_URL}images/${logo}`} 
                alt="logo.png" className="image"></img>:
            ''}
            <div className="home-details">
                <h1>Get a discount on parking</h1>
                <p className="small">Fill in your details and you pay less on exit.</p>
            </div>
            <button 
                className="home-button" 
                style={buttonStyle}
                onClick={e => goToDetailsForm()}>Fill in details
            </button>
        </div>
    )
}

export default Home
